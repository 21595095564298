import clsx from 'clsx';
import Image from 'components/common/Image';
import LinkComponent from 'components/common/Link';
import { rem } from 'lib';
import { ProductProps } from 'interfaces/cms/content';
import ButtonComponent from 'components/common/Button';
import Markdown from 'components/common/Markdown';
import styles from '../ProductOverview.module.scss';

function BigProductCard({
  id,
  url,
  alt,
  title,
  subTitle,
  linkUrl,
  linkSlug,
  button,
}: Readonly<ProductProps>) {
  const isNoLink = !linkSlug && !linkUrl;
  return (
    <div
      key={id}
      className={clsx(
        'relative bg-white rounded-[10px] border-[#f4f8fe]  border-[1px] border-solid text-[#4f4b66] flex flex-col flex-wrap items-center justify-center w-[104px] m-[5px] py-[15px] px-[5px] shadow-product-overview md:w-[160px] md:m-[9px] md:py-[30px] md:px-[20px] focus:shadow-none checked:shadow-none active:shadow-none',
        'rounded-[15px] w-[150px] m-[5px] md:w-[255px] md:m-[10px] hover:bg-white hover:shadow-product-overview hover:no-underline'
      )}
    >
      <LinkComponent
        href={linkSlug || linkUrl || ''}
        rel={button?.buttonLinkRel}
        isExternalLink={!!linkUrl}
      >
        <div className="p-[1.25rem] md:p-[1.875rem]">
          <div
            className={clsx(
              'flex flex-wrap flex-row items-start relative h-0 pb-[75%]'
            )}
          >
            <Image alt={alt} src={url} width={195} height={146} />
          </div>
          <div
            className={clsx(
              'pt-[0.625rem] lg:pt-[1.25rem] text-center font-bold md:font-normal',
              styles.ProductOverview__cardTitle
            )}
          >
            <span className="px-[20px] py-0 h-[4.5em] md:p-[20px_0_0_0] font-bold md:font-normal text-[16px] md:text-[14px] leading-[20px] w-full">
              <Markdown
                md={title}
                textAlign="center"
                fontSize={rem(18)}
                textColor="#4f4b66"
              />
            </span>
          </div>
          <div
            className={clsx(
              'hidden md:block h-[48px] overflow-hidden pt-[5px] flex-wrap items-start text-[14px] font-normal leading-[18px] w-[100%]'
            )}
          >
            <h5 className="text-center text-textColor">
              <Markdown
                md={subTitle || ''}
                textAlign="center"
                fontSize={rem(14)}
              />
            </h5>
          </div>

          {!isNoLink && (
            <div className="'w-full hidden md:flex self-center pt-[1.25rem]">
              <div className="text-center !w-full block m-0 text-ellipsis overflow-hidden">
                <ButtonComponent {...button} />
              </div>
            </div>
          )}
        </div>
      </LinkComponent>
    </div>
  );
}

export default BigProductCard;
