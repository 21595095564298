import { CarouselJsonLd } from 'next-seo';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useCallback } from 'react';

import LinkComponent from 'components/common/Link';
import ParagraphHeader from 'components/ParagraphHeader';
import ButtonComponent from 'components/common/Button';

import GTMHelper from 'lib/GTMHelper';
import { ProductOverviewProps } from 'interfaces/cms/content';

import BigProductCard from './Card/BigProductCard';
import ProductCard from './Card/ProductCard';
import { baseUrl } from 'lib/constants';

const PRODUCT_OVERVIEW_STORAGE_KEY = 'productOverviewUrl';

function ProductOverview({
  background,
  header,
  product,
  seeMoreButton,
  cardSize,
}: Readonly<ProductOverviewProps>) {
  const { asPath, locale } = useRouter();
  const localePath = locale === 'th' ? '' : '/en';

  const carouselData = product?.map((list) => {
    const url = list.linkSlug
      ? `https://rabbitcare.com${localePath}/${list.linkSlug}`
      : list.linkUrl;
    return url ? { url } : { url: null };
  });

  const onClickProductOrSeeMore = useCallback(
    (slug: string | null, url: string | null) => {
      const storage = window.localStorage;

      const _slug = slug ?? '';
      const _url = url ?? '';

      const selectedProduct = product?.find((list) => list.linkSlug === _slug);
      selectedProduct && GTMHelper.clickRcHomeCategory(selectedProduct.title);

      storage.setItem(
        PRODUCT_OVERVIEW_STORAGE_KEY,
        `/${_slug}` || _url?.replace(baseUrl, '')
      );
    },
    [product]
  );

  const onClickSeeMore = useCallback(
    () =>
      seeMoreButton?.buttonPageSlug?.slug &&
      seeMoreButton?.buttonUrl &&
      onClickProductOrSeeMore(
        seeMoreButton?.buttonPageSlug?.slug,
        seeMoreButton?.buttonUrl
      ),
    [
      seeMoreButton?.buttonPageSlug?.slug,
      seeMoreButton?.buttonUrl,
      onClickProductOrSeeMore,
    ]
  );

  return (
    <>
      <CarouselJsonLd ofType="default" data={carouselData} />
      <div style={{ backgroundColor: background }}>
        <div className="py-[1.875rem] px-[0.938rem] max-w-[69.375rem] mx-auto">
          {header && (
            <div className="mx-auto pb-[1.25rem]">
              <ParagraphHeader {...header} />
            </div>
          )}
          {product && (
            <div
              className={clsx(
                'flex flex-wrap justify-center mx-auto',
                cardSize === 'normal'
                  ? 'w-full lg:w-[80%] xl:w-[100%]'
                  : 'w-full'
              )}
            >
              {cardSize === 'normal'
                ? product.map((item) => (
                    <ProductCard
                      key={item.id}
                      {...item}
                      onClickProductOrSeeMore={onClickProductOrSeeMore}
                    />
                  ))
                : product.map((item) => (
                    <BigProductCard key={item.id} {...item} />
                  ))}
            </div>
          )}

          {seeMoreButton &&
            (seeMoreButton.buttonPageSlug || seeMoreButton.buttonUrl) && (
              <div className="mt-[1.875rem] mx-auto text-center w-full md:w-[31.25rem]">
                {asPath === '/' ? (
                  <ButtonComponent
                    isRowButton
                    {...seeMoreButton}
                    buttonType="outline"
                    buttonSize="normal"
                    onClick={onClickSeeMore}
                  />
                ) : (
                  <LinkComponent
                    href={
                      seeMoreButton.buttonPageSlug
                        ? seeMoreButton.buttonPageSlug?.slug
                        : seeMoreButton.buttonUrl
                    }
                    rel={seeMoreButton.buttonLinkRel}
                    className="text-center"
                    isExternalLink={!!seeMoreButton.buttonUrl}
                  >
                    <ButtonComponent
                      isRowButton
                      {...seeMoreButton}
                      buttonType="outline"
                      buttonSize="normal"
                    />
                  </LinkComponent>
                )}
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default ProductOverview;
